import React from "react";
import { usePlantCtx } from "../../Hooks/usePlantContext";
import { validateEmail } from "../../utils";
const DetailSection = ({ onNext, onBack }) => {
  const {
    dataState: { plantName, userAddress, plantQuantity, additionalRequest },
    onChangeInput,
    onIncrementQty,
    onDecrementQty,
  } = usePlantCtx();
  // DISABLED THE NEXT BUTTON IF EMAIL / ADDITIONAL-REQ FIELD IS EMPTY
  const isNextBtnDisabled =
    `${userAddress}`.length <= 0 || `${additionalRequest}`.length <= 0;

  // DISABLED THE IS-DECREMENT BUTTON IF QTY IS 1
  const isDecBtnDisabled = plantQuantity === 1;
  return (
    <div className="equaly-space gap-12">
      <h2>{plantName}</h2>
      <div className="equaly-space gap-05">
        <label htmlFor="userAddress">Where should we send this?</label>
        <input
          id="userAddress"
          className="w-6"
          name="userAddress"
          type="text"
          value={userAddress}
          onChange={onChangeInput}
          required={true}
        />
      </div>
      <div className="equaly-space gap-05">
        <label>How many plants?</label>
        <div className="quantity-wrapper">
          <button
            className="outline"
            onClick={onDecrementQty}
            disabled={isDecBtnDisabled}
          >
            -
          </button>
          <span className="flex-c ">{plantQuantity}</span>
          <button className="outline" onClick={onIncrementQty}>
            +
          </button>
        </div>
      </div>
      <div className="equaly-space gap-05">
        <label htmlFor="additionalRequest">Anything else to say?</label>
        <input
          id="additionalRequest"
          className="w-6"
          name="additionalRequest"
          type="text"
          value={additionalRequest}
          onChange={onChangeInput}
          required={true}
        />
      </div>
      <div className="equaly-space gap-05">
        <button onClick={onNext} disabled={isNextBtnDisabled}>
          next
        </button>
        <button className="outline" onClick={onBack}>
          back
        </button>
      </div>
    </div>
  );
};

DetailSection.defaultProps = {
  onNext: () => {},
  onBack: () => {},
};

export default DetailSection;
