import React, { useState } from "react";

import { fbCloudDb, serverTimestamp, useAuth } from "../../Hooks/useFirebase";
import { usePlantCtx } from "../../Hooks/usePlantContext";
import Spinner from "../../utils/Spinner";

const ConfirmSection = ({ onBack, onConfirm }) => {
  const {
    dataState: { plantName, userAddress, plantQuantity, additionalRequest },
  } = usePlantCtx();
  const { user } = useAuth();
  /**
   * STATE FOR LOADING/ERROR
   */
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * FUNCTION FOR ADDING DATA TO FIREBASE
   */
  const onSubmitConfirmation = async () => {
    try {
      setLoading(true);
      const newDoc = fbCloudDb.collection("plants").doc();
      const data = {
        plantName,
        plantQuantity,
        additionalRequest,
        createdAt: serverTimestamp(),
        userAddress,
        uid: user?.uid || "",
        email: user?.email || "",

        // QUOTE BY DETIALS

        quoteBy: null,
      };
      const res = await newDoc.set(data);
      onConfirm();
    } catch (err) {
      console.log(`err`, err);
    }
  };
  return (
    <div className="equaly-space gap-12">
      <h2>{plantName}</h2>
      <div className="equaly-space gap-05">
        <p>{userAddress}</p>
        <p>{plantQuantity}</p>
        <p>{additionalRequest}</p>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="equaly-space gap-05">
          <button onClick={onSubmitConfirmation} disabled={loading}>
            Confirm
          </button>
          <button className="outline" onClick={onBack} disabled={loading}>
            back
          </button>
        </div>
      )}
    </div>
  );
};

ConfirmSection.defaultProps = {
  onBack: () => {},
  onConfirm: () => {},
};

export default ConfirmSection;
