import React from "react";
import { useAuth } from "../Hooks/useFirebase";
import AuthSection from "../Page/AuthSession";
import EmailVerification from "../Components/EmailVerification";

const PrivateComp = ({ children }) => {
  const { user } = useAuth();
  const isAuthenticatedUser = !!user;
  const isVerifiedUser = user?.emailVerified;

  if (isAuthenticatedUser) {
    return isVerifiedUser ? <>{children}</> : <EmailVerification />;
  } else {
    return <AuthSection />;
  }
};

export default PrivateComp;
