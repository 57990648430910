import React from "react";
import { usePlantCtx } from "../Hooks/usePlantContext";

const Home = ({ onRequest }) => {
  const {
    dataState: { plantName },
    onChangeInput,
  } = usePlantCtx();
  // DISABLED THE REQUEST BUTTON IF PLANT-NAME FIELD IS EMPTY
  const isRequestBtnDisabled = `${plantName}`.length <= 0;
  return (
    <div className="equaly-space">
      <h1>What produce do you request?</h1>
      <h3>Nature's tasty banquet grown by local gardeners.</h3>
      <input
        className="w-6"
        name="plantName"
        type="text"
        value={plantName}
        onChange={onChangeInput}
        required={true}
      />
      <button onClick={onRequest} disabled={isRequestBtnDisabled}>
        request
      </button>
      <h3></h3>
      <h3></h3>
      <h3></h3>
      <h3></h3>
      <h3></h3>
      <h3>Contact Us: shareablefarms@gmail.com</h3>
    </div>
  );
};

Home.defaultProps = {
  onRequest: () => {},
};

export default Home;
