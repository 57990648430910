import React, { useState } from "react";
import { useAuth } from "../Hooks/useFirebase";
import Spinner from "../utils/Spinner";

const EmailVerification = () => {
  const { sendEmailVerification } = useAuth();
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSendEmail = async (e) => {
    try {
      setLoading(true);

      await sendEmailVerification();
      setMsg("Refresh page after verifying your email.");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(`err`, err);
      setMsg("There is something wrong, please try again later.");
    }
  };
  return (
    <div className="equaly-space">
      <h3>Your Email is not verified.</h3>
      {loading ? (
        <Spinner />
      ) : (
        <button className="outline border" onClick={onSendEmail}>
          Please verify Email
        </button>
      )}
      {msg && <p>{msg}</p>}
    </div>
  );
};

export default EmailVerification;
