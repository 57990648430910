// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

import firebaseConfig from "../fbConfig";

// Add your Firebase credentials
firebase.initializeApp(firebaseConfig);
export const fbFirestore = firebase.firestore;
export const fbCloudDb = firebase.firestore();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const firebaseInstance = firebase;

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export default function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signup = (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const createUserDoc = (id, data) => {
    return fbCloudDb.collection("users").doc(id).set(data);
  };
  const updateUserProfile = (obj) => {
    return firebase.auth().currentUser.updateProfile(obj);
  };
  const sendEmailVerification = () => {
    return firebase.auth().currentUser.sendEmailVerification();
  };
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    setLoading(true);
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      setLoading(true);
      if (user) {
        const email = user.email;
        const photoUrl = user.photoURL;
        const emailVerified = user.emailVerified;
        const uid = user.uid;
        const displayName = email.split("@")[0];
        setUser({ displayName, email, photoUrl, emailVerified, uid });
        setLoading(false);
      } else {
        setLoading(false);
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    loading,
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    setUser,
    updateUserProfile,
    createUserDoc,
    sendEmailVerification,
  };
}
