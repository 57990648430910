import React from "react";

import LogoComp from "./Components/Logo";
import Header from "./Components/Header";

import Spinner from "./utils/Spinner";
import RoutesComp from "./Routes";

import { useAuth } from "./Hooks/useFirebase";

const App = () => {
  const { loading } = useAuth();

  return (
    <div className="App">
      <LogoComp />
      <Header />
      {loading ? <Spinner /> : <RoutesComp />}
    </div>
  );
};

export default App;

//  {/* HOME */}
//  {currentSection === "HOME" && (
//   <HomeSection onRequest={() => setCurrentSection("DETAIL")} />
// )}
// {loading && <Spinner />}
// {currentSection !== "HOME" && !loading && showSection}
