import React from "react";
import PrivateComp from "../../utils/PrivateComp";
import RequestConfirm from "../../Components/PlantRequest/RequestConfirm";

const RequestConfirmPage = ({ history }) => {
  return (
    <PrivateComp>
      <RequestConfirm
        onBack={() => history.push("/request-form")}
        onConfirm={() => history.push("/request-feedback")}
      />
    </PrivateComp>
  );
};

export default RequestConfirmPage;
