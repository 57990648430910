import React, { useEffect, useState } from "react";
import { fbCloudDb } from "../../Hooks/useFirebase";
import { useQuoteCtx } from "../../Hooks/useQuoteContext";

const AllPantsRequest = ({ history }) => {
  const { setDataState } = useQuoteCtx();
  const [plantReqList, setPlantReqList] = useState([]);
  useEffect(() => {
    getPlants();
  }, []);

  const getPlants = async () => {
    try {
      const res = await fbCloudDb
        .collection("plants")
        .orderBy("createdAt", "desc")
        .where("quoteBy", "==", null)
        .limit(6)
        .get();
      const list = res.docs.map((o) => ({ ...o.data(), docId: o.id }));
      setPlantReqList(list);
    } catch (err) {
      console.log(`err`, err);
    }
  };

  const onPlantClick = (data) => {
    history.push("/quote-form");
    setDataState({ ...data, quoteAmount: 0, estimatedDelivery: 1 });
  };
  return (
    <div className="equaly-space">
      <h2>Could you grow any of these?</h2>
      <div className="equaly-space gap-05">
        {plantReqList.map((o) => (
          <button
            key={o.docId}
            className="outline border"
            onClick={() => onPlantClick(o)}
          >
            {o?.plantName || ""}
          </button>
        ))}
      </div>
      <button className="outline" onClick={() => history.push("/")}>
        back
      </button>
    </div>
  );
};

export default AllPantsRequest;
