import React, { useState } from "react";
import { useAuth } from "../Hooks/useFirebase";
import Spinner from "../utils/Spinner";

const AuthSection = () => {
  const { user, signin, signup, signout } = useAuth();
  // curOption-> LOGIN / SIGNUP
  const [curOption, setCurOption] = useState("LOGIN");
  const [formData, setFormData] = useState({ email: "", password: "" });
  /**
   * STATE FOR LOADING/ERROR
   */
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { email, password } = formData;
      if (curOption === "LOGIN") {
        await signin(email, password);
      } else {
        await signup(email, password);
      }
    } catch (err) {
      setLoading(false);
      const code = err?.code;
      if (code === "auth/user-not-found") {
        setError("Invalid Creds");
      } else {
        setError(`${code}`);
      }
      console.log(`err`, err);
    }
  };
  // ON CHANGE HANDLER FOR INPUT FIELDS
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const changeCurHandler = () => {
    if (curOption === "LOGIN") {
      setCurOption("SIGNUP");
    } else {
      setCurOption("LOGIN");
    }
  };
  return (
    <div className="w-6 w-full">
      <form
        autoComplete="false"
        className="equaly-space "
        onSubmit={onSubmitHandler}
      >
        <h2>{curOption}</h2>
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData["email"]}
          onChange={changeHandler}
          required
          placeholder="email"
        />
        <label>Password</label>
        <input
          type="password"
          name="password"
          value={formData["password"]}
          onChange={changeHandler}
          required
          placeholder="password"
        />
        {error && <p className="text-red text-center">{error}</p>}
        {loading ? (
          <Spinner />
        ) : (
          <div className="equaly-space gap-05">
            <button type="submit">{curOption}</button>
            <button
              type="button"
              className="outline"
              style={{ fontSize: "0.8rem" }}
              onClick={changeCurHandler}
            >
              {curOption === "LOGIN"
                ? "Need an account? Signup"
                : "Have an account? Login"}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default AuthSection;
