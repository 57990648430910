import React from "react";
import { Link } from "react-router-dom";
import UserShow from "./UserShow";
import styles from "./header.module.css";

const Header = () => {
  return (
    <>
      <Link to="/all-plant-requests" className={styles.sellGardenBtn}>
        <button className="outline border">sell your garden</button>
      </Link>
      <UserShow />
    </>
  );
};

export default Header;
