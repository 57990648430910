import React from "react";
import PrivateComp from "../../utils/PrivateComp";

import { useQuoteCtx, initialData } from "../../Hooks/useQuoteContext";
const RequestDetail = ({ history }) => {
  const { setDataState } = useQuoteCtx();

  const onDone = () => {
    history.push("/");
    setDataState(initialData);
  };
  return (
    <PrivateComp>
      <div className="equaly-space">
        <h2>Hang tight!</h2>
        <div>
          <p>We will notify you when this customer</p>
          <p>responds to your quote</p>
        </div>
        <button style={{ marginTop: "1rem" }} onClick={onDone}>
          Great!
        </button>
      </div>
    </PrivateComp>
  );
};

export default RequestDetail;
