import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./Page/HomePage";
// PLANT REQUEST PAGES
import RequestForm from "./Page/PlantRequest/RequestForm";
import RequestConfirm from "./Page/PlantRequest/RequestConfirm";
import RequestFeedback from "./Page/PlantRequest/Feedback";

// PLANT QUOTE PAGES
import AllPantsRequestPage from "./Page/AllPantsRequest";
import QuoteFormPage from "./Page/PlantQuote/QuoteForm";
import QuoteConfirmPage from "./Page/PlantQuote/QuoteConfirm";
import QuoteFeedback from "./Page/PlantQuote/Feedback";

const Routes = () => {
  return (
    <Switch>
      {/* PLANT REQUEST */}
      <Route path="/request-form" component={RequestForm} />
      <Route path="/request-confirm" component={RequestConfirm} />
      <Route path="/request-feedback" component={RequestFeedback} />
      {/* PLANT LIST FOR GROW */}
      <Route path="/all-plant-requests" component={AllPantsRequestPage} />
      <Route path="/quote-form" component={QuoteFormPage} />
      <Route path="/quote-confirm" component={QuoteConfirmPage} />
      <Route path="/quote-feedback" component={QuoteFeedback} />

      <Route exact path="/" component={HomePage} />
    </Switch>
  );
};

export default Routes;
