import React from "react";
import PrivateComp from "../../utils/PrivateComp";

import { initialData, usePlantCtx } from "../../Hooks/usePlantContext";
const RequestDetail = ({ history }) => {
  const { setDataState } = usePlantCtx();

  const onDone = () => {
    history.push("/");
    setDataState(initialData);
  };
  return (
    <PrivateComp>
      <div className="equaly-space">
        <h2>Hang tight while we look for a grower!</h2>
        <div>
          <p>Expect an email with price and lead</p>
          <p>time soon.</p>
        </div>
        <button style={{ marginTop: "1rem" }} onClick={onDone}>
          Great!
        </button>
      </div>
    </PrivateComp>
  );
};

export default RequestDetail;
