import React, { useEffect } from "react";
import QuoteForm from "../../Components/PlantQuote/QuoteForm";
import { useQuoteCtx } from "../../Hooks/useQuoteContext";
import PrivateComp from "../../utils/PrivateComp";

const QuoteFormPage = ({ location, history }) => {
  const {
    dataState: { plantName, plantQuantity, additionalRequest, docId },
  } = useQuoteCtx();
  useEffect(() => {
    if (!docId) {
      history.push("/all-plant-requests");
    }
  }, []);
  return (
    <PrivateComp>
      <div className="equaly-space gap-12">
        <div className="equaly-space gap-05">
          <h2>{plantName}</h2>
          <p>Quantity: {plantQuantity}</p>
          <p>{additionalRequest}</p>
        </div>
        <QuoteForm
          onNext={() => history.push("/quote-confirm")}
          onBack={() => history.push("/all-plant-requests")}
        />
      </div>
    </PrivateComp>
  );
};

export default QuoteFormPage;
