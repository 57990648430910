import React from "react";
import PrivateComp from "../../utils/PrivateComp";
import QuoteConfirm from "../../Components/PlantQuote/QuoteConfirm";

const QuoteConfirmPage = ({ history }) => {
  return (
    <PrivateComp>
      <QuoteConfirm
        onBack={() => history.push("/quote-form")}
        onConfirm={() => history.push("/quote-feedback")}
      />
    </PrivateComp>
  );
};

export default QuoteConfirmPage;
