import React, { useState } from "react";

import { fbCloudDb, serverTimestamp, useAuth } from "../../Hooks/useFirebase";
import { usePlantCtx } from "../../Hooks/usePlantContext";
import { useQuoteCtx } from "../../Hooks/useQuoteContext";
import Spinner from "../../utils/Spinner";

const ConfirmSection = ({ onBack, onConfirm }) => {
  const {
    dataState: {
      plantName,
      docId,
      plantQuantity,
      additionalRequest,
      quoteAmount,
      estimatedDelivery,
    },
  } = useQuoteCtx();
  const { user } = useAuth();
  /**
   * STATE FOR LOADING/ERROR
   */
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * FUNCTION FOR ADDING DATA TO FIREBASE
   */
  const onSubmitConfirmation = async () => {
    try {
      setLoading(true);
      const docRef = fbCloudDb.collection("plants").doc(docId);
      const data = {
        // QUOTE BY DETIALS
        quoteBy: user?.uid || "",
        quoteAmount,
        estimatedDelivery,
      };
      await docRef.update(data);
      onConfirm();
    } catch (err) {
      console.log(`err`, err);
    }
  };
  return (
    <div className="equaly-space gap-12">
      <h2>{plantName}</h2>
      <div className="equaly-space gap-05">
        <p>{plantQuantity} Plants</p>
        <p>{additionalRequest}</p>
      </div>
      <div className="equaly-space gap-05">
        <p>
          <span>Your Quote:</span>
          {quoteAmount}
        </p>
        <p>Estimated Delivery 'till delivery: {estimatedDelivery}</p>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="equaly-space gap-05">
          <button onClick={onSubmitConfirmation} disabled={loading}>
            send your quote
          </button>
          <button className="outline" onClick={onBack} disabled={loading}>
            back
          </button>
        </div>
      )}
    </div>
  );
};

ConfirmSection.defaultProps = {
  onBack: () => {},
  onConfirm: () => {},
};

export default ConfirmSection;
