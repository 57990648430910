import React from "react";
import { useQuoteCtx } from "../../Hooks/useQuoteContext";

const DetailSection = ({ onNext, onBack }) => {
  const {
    dataState: { quoteAmount, estimatedDelivery },
    onChangeInput,
    onIncrementQty,
    onDecrementQty,
  } = useQuoteCtx();
  // DISABLED THE NEXT BUTTON IF EMAIL / ADDITIONAL-REQ FIELD IS EMPTY
  const isNextBtnDisabled = quoteAmount <= 0;

  // DISABLED THE IS-DECREMENT BUTTON IF QTY IS 1
  const isDecBtnDisabled = estimatedDelivery === 1;
  return (
    <div className="equaly-space gap-12">
      <div className="equaly-space gap-05">
        <label htmlFor="quoteAmount">How much USD do you want for this grow?</label>
        <input
          id="quoteAmount"
          className="w-6"
          name="quoteAmount"
          type="number"
          value={quoteAmount}
          onChange={onChangeInput}
          required={true}
        />
      </div>
      <div className="equaly-space gap-05">
        <label>Estimated weeks 'till delivery</label>
        <div className="quantity-wrapper">
          <button
            className="outline"
            onClick={onDecrementQty}
            disabled={isDecBtnDisabled}
          >
            -
          </button>
          <span className="flex-c ">{estimatedDelivery} week(s)</span>
          <button className="outline" onClick={onIncrementQty}>
            +
          </button>
        </div>
      </div>
      <div className="equaly-space gap-05">
        <button onClick={onNext} disabled={isNextBtnDisabled}>
          next
        </button>
        <button className="outline" onClick={onBack}>
          back
        </button>
      </div>
    </div>
  );
};

DetailSection.defaultProps = {
  onNext: () => {},
  onBack: () => {},
};

export default DetailSection;
