import React from "react";
import { useHistory } from "react-router";
import logo from "../assets/PlantAppLogo.png";
import { usePlantCtx } from "../Hooks/usePlantContext";

const Logo = () => {
  const history = useHistory();
  return (
    <div className="logo-wrapper" onClick={() => history.push("/")}>
      <img className="logo" src={logo} srcSet={logo} alt="ShareableFarms" />
      {/* <h1>ShareableFarms</h1> */}
    </div>
  );
};

export default Logo;
