import React from "react";
import PrivateComp from "../../utils/PrivateComp";
import RequestForm from "../../Components/PlantRequest/RequestForm";

const RequestFormPage = ({ history }) => {
  return (
    <PrivateComp>
      <RequestForm
        onNext={() => history.push("/request-confirm")}
        onBack={() => history.push("/")}
      />
    </PrivateComp>
  );
};

export default RequestFormPage;
