import React, { useState, useContext, createContext } from "react";

const plantQuoteContext = createContext();

export const useQuoteCtx = () => {
  return useContext(plantQuoteContext);
};
export const initialData = {
  quoteAmount: 0,
  estimatedDelivery: 1,
  plantName: "",
  plantQuantity: 1,
  additionalRequest: "",
  docId: "",
};
export default function QuoteCtxProvider({ children }) {
  /**
   * STATE FOR VARIABLES
   */
  const [dataState, setState] = useState(initialData);
  // HELPER FUNCTION FOR DATA STATE
  const setDataState = (obj) => {
    setState((p) => ({ ...p, ...obj }));
  };
  /**
   * STATE FOR CHANGING CURRENT PAGE
   */
  const [currentSection, setCurrentSection] = useState("HOME");

  // ON CHANGE HANDLER FOR INPUT FIELDS
  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setDataState({ [name]: value });
  };

  // INCREMENT FUNCTION FOR QUANTITY
  const onIncrementQty = () => {
    setDataState({ estimatedDelivery: dataState.estimatedDelivery + 1 });
  };
  // DECREMENT FUNCTION FOR QUANTITY
  const onDecrementQty = () => {
    setDataState({ estimatedDelivery: dataState.estimatedDelivery - 1 });
  };

  return (
    <plantQuoteContext.Provider
      value={{
        currentSection,
        setCurrentSection,
        dataState,
        setDataState,
        onChangeInput,
        onIncrementQty,
        onDecrementQty,
      }}
    >
      {children}
    </plantQuoteContext.Provider>
  );
}
